import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as RadixLabel from '@radix-ui/react-label';
import TextareaAutosize from 'react-textarea-autosize';

// Utils
import bemify from 'utils/bemify';

// Components
import Icon from 'components/icon';
import Loader from 'components/loader';

// Styles
import stylesModule from './styles.module.scss';


const styles = bemify(stylesModule);

const Input = forwardRef(({
  block,
  className,
  description,
  disabled,
  displayRequiredMark,
  feedback,
  id,
  inputClassName,
  inputWrapperClassName,
  isAwaitingFeedback,
  label,
  leftSlot,
  leftSlotClassName,
  onChange,
  required,
  rightSlot,
  slotMarginSize,
  type,
  validationState,
  warningMessage,
  ...inputProps
}, ref) => {
  // Common classes for both input and textarea
  const commonClasses = `${styles.input__input}${
    validationState === 'error' || feedback?.status === 'error' ? ` ${styles.input__input_error}` : ''}${
    leftSlot ? ` ${styles.input__input_extraPaddingLeft}` : ''}${
    leftSlot && slotMarginSize === 's' ? ` ${styles.input__input_extraPaddingLeftSmall}` : ''}${
    rightSlot ? ` ${styles.input__input_extraPaddingRight}` : ''}${
    rightSlot && slotMarginSize === 's' ? ` ${styles.input__input_extraPaddingRightSmall}` : ''}`;

  const colorCode = useMemo(() => {
    if (type !== 'color') return null;

    const { value: colorValue } = inputProps || {};

    if (colorValue && typeof colorValue === 'string' && colorValue[0] === '#') return colorValue.slice(1).toLocaleUpperCase();

    return '000000';
  }, [inputProps, type]);

  return (
    <div className={`${styles.input}${
      block ? ` ${styles.input_block}` : ''}${className ? ` ${className}` : ''}${
      disabled ? ` ${styles.input_disabled}` : ''}`}
    >
      {(!!label || !!warningMessage || !!feedback) && (
        <div className="d-flex justify-content-between">
          {!!label && (
            <RadixLabel.Root
              className={styles.input__label}
              htmlFor={id}
            >
              {label}
              {(required || displayRequiredMark) && (
                <span className={`${styles.input__labelRequiredMark}${
                  validationState === 'error' ? ` ${styles.input__labelRequiredMark_error}` : ''}`}
                >
                  {' '}
                  *
                </span>
              )}
            </RadixLabel.Root>
          )}
          {(!!warningMessage || !!feedback) && !isAwaitingFeedback && (
            <div className="d-flex align-items-center">
              <Icon
                className={`${styles.input__messageIcon}${!warningMessage && feedback.status === 'success' ? ` ${styles.input__messageIcon_success}` : ` ${styles.input__messageIcon_warning}`}`}
                name={warningMessage || feedback?.status === 'error' ? 'warning-circle' : 'check-circle--green'}
              />
              <div className={`${styles.input__message}${!warningMessage && feedback.status === 'success' ? ` ${styles.input__message_success}` : ` ${styles.input__message_warning}`}`}>
                {warningMessage || feedback?.message}
              </div>
            </div>
          )}
          {isAwaitingFeedback && (
            <Loader
              className={styles.input__feedbackLoader}
            />
          )}
        </div>
      )}
      <div className={`${styles.input__inputWrapper}${inputWrapperClassName ? ` ${inputWrapperClassName}` : ''}`}>
        {!!leftSlot && (
          <div className={`${styles.input__slot} ${styles.input__slot_left}${
            slotMarginSize === 'none' ? ` ${styles.input__slot_noMargin}` : ''}${
            slotMarginSize === 's' ? ` ${styles.input__slot_smallMargin}` : ''}${
            leftSlotClassName ? ` ${leftSlotClassName}` : ''}`}
          >
            {leftSlot}
          </div>
        )}
        {type === 'textarea' &&
          (
            <TextareaAutosize
              ref={ref}
              className={`${commonClasses} ${styles.input__input_textarea}${inputClassName ? ` ${inputClassName}` : ''}`}
              disabled={disabled}
              id={id}
              required={required}
              onChange={event => onChange(event?.target?.value)}
              {...inputProps}
            />
          )}
        {type === 'color' &&
          (
            <label
              className={`${commonClasses} ${styles.input__input_flex}${inputClassName ? ` ${inputClassName}` : ''}`}
            >
              <div className={styles.input__colorInputWrapper}>
                <input
                  ref={ref}
                  className={styles.input__colorInput}
                  disabled={disabled}
                  id={id}
                  required={required}
                  type={type}
                  onChange={event => onChange(event?.target?.value)}
                  {...inputProps}
                />
              </div>
              <span>
                {colorCode}
              </span>
            </label>
          )}
        {type !== 'textarea' && type !== 'color' &&
          (
            <input
              ref={ref}
              className={`${commonClasses}${inputClassName ? ` ${inputClassName}` : ''}`}
              disabled={disabled}
              id={id}
              required={required}
              type={type}
              onChange={event => onChange(event?.target?.value)}
              {...inputProps}
            />
          )}
        {!!rightSlot && (
          <div className={`${styles.input__slot}${
            slotMarginSize === 'none' ? ` ${styles.input__slot_noMargin}` : ''}${
            slotMarginSize === 's' ? ` ${styles.input__slot_smallMargin}` : ''}`}
          >
            {rightSlot}
          </div>
        )}
      </div>
      {!!description && (
        <span className={`${styles.input__description}${validationState === 'error' ? ` ${styles.input__description_error}` : ''}`}>
          {description}
        </span>
      )}
    </div>
  );
});

Input.propTypes = {
  block: PropTypes.bool,
  className: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  disabled: PropTypes.bool,
  displayRequiredMark: PropTypes.bool,
  feedback: PropTypes.shape({
    message: PropTypes.string,
    status: PropTypes.oneOf(['success', 'error'])
  }),
  icon: PropTypes.string,
  id: PropTypes.string,
  inputClassName: PropTypes.string,
  inputWrapperClassName: PropTypes.string,
  isAwaitingFeedback: PropTypes.bool,
  label: PropTypes.string,
  leftSlot: PropTypes.element,
  leftSlotClassName: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  rightSlot: PropTypes.element,
  slotMarginSize: PropTypes.oneOf(['none', 'default', 's']),
  type: PropTypes.string,
  validationState: PropTypes.oneOf(['default', 'error']),
  warningMessage: PropTypes.string
};

Input.defaultProps = {
  block: false,
  className: null,
  description: null,
  disabled: false,
  displayRequiredMark: false,
  feedback: null,
  icon: null,
  id: 'input',
  inputClassName: null,
  inputWrapperClassName: null,
  isAwaitingFeedback: false,
  label: null,
  leftSlot: null,
  leftSlotClassName: null,
  onChange: () => {},
  required: false,
  rightSlot: null,
  slotMarginSize: 'default',
  type: 'text',
  validationState: 'default',
  warningMessage: null
};

Input.displayName = 'Input';

export default Input;
